import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import { SubscriptionPackage } from '../types/subscriptions.type';
import axios from 'axios';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  padding: '0 0 0 8px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    p: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1em'
    }
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)'
  })
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  paddingLeft: '62px'
}));

export const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

type PaymentFormProps = {
  setData: (packageId: number, subId: number) => void;
};

// TODO: debit_order_day field

export default function Subscriptions({ setData, ...props }: PaymentFormProps) {
  const [packages, setPackages] = React.useState<SubscriptionPackage[]>([]);
  const [expanded, setExpanded] = React.useState<number | false>(1);
  const [subscription, setSubscription] = React.useState<number>(4);

  React.useEffect(() => {
    async function getData() {
      const { data } = await axios.get('subscription-packages');
      setPackages(data);
    }
    getData();
    setData(Number(expanded), subscription);
  }, [expanded, subscription]);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <FormControl sx={{ marginBottom: 4 }}>
      {packages.map((item, index) => {
        return (
          <Accordion
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
            key={`package-${index}`}
          >
            <AccordionSummary
              aria-controls={`panel${index}d-content`}
              id="panel1d-header"
            >
              <Typography>
                <Radio
                  value={item.id}
                  onChange={(e) => setExpanded(Number(item.id))}
                  name="package"
                  checked={expanded === item.id}
                />
                {item.name} {item.extra_info && <>({item.extra_info})</>}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl>
                <RadioGroup
                  defaultValue={subscription}
                  name="subscription"
                  onChange={(e) => setSubscription(Number(e.target.value))}
                >
                  {item.subscriptions.map((sub, subIndex) => {
                    return (
                      <FormControlLabel
                        key={`subscription-${subIndex}`}
                        control={<Radio value={sub.id} />}
                        label={`${sub.name} (R${sub.cost} per month)`}
                        value={sub.id}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </FormControl>
  );
}
