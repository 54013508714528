import * as React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const addresses = ['1 MUI Drive', 'Reactville', 'Anytown', '99999', 'USA'];
const payments = [
  { name: 'Card type:', detail: 'Visa' },
  { name: 'Card holder:', detail: 'Mr. John Smith' },
  { name: 'Card number:', detail: 'xxxx-xxxx-xxxx-1234' },
  { name: 'Expiry date:', detail: '04/2024' }
];

export default function Review() {
  return (
    <Stack spacing={2}>
      {/* <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Products" secondary="4 selected" />
          <Typography variant="body2">$134.98</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Shipping" secondary="Plus taxes" />
          <Typography variant="body2">$9.99</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            $144.97
          </Typography>
        </ListItem>
      </List>
      <Divider /> */}
      <Divider>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
      </Divider>
      <Typography gutterBottom>
        I hereby apply for membership at Elite. Monthly Membership fees (as
        stated below) shall be paid by me in advance, before the 1st of each
        month. Membership is paid on a monthly basis.
      </Typography>

      <Typography gutterBottom>
        I acknowledge that membership will entitle me to attend any or all
        classes to receive tuition and practice in the art of Muay Thai, Goju
        Ryu Karate, Calisthenics, High Intensity training and fitness. Further
        agreeing that my absence from attending such classes will in no way
        whatsoever entitle me to a refund or suspension of payment of my
        membership fee or part thereof. Where private classes in fitness and/ or
        self defense are required from Elite Muay Thai, these classes and
        payments will be arranged separately and on an ad-hoc basis.
      </Typography>

      <Typography gutterBottom>
        I will not maliciously and willfully use my knowledge to do unlawful
        injury to another, but shall abide by the rules of Muay Thai and Goju
        Ryu Karate, as laid down and honorably conduct myself at all times.
      </Typography>

      <Typography gutterBottom>
        I further indemnify and hold harmless, Elite in respect of any personal
        loss, damage, theft or injury suffered by me while attending these
        classes. I further indemnify Elite in respect of damage stemming from
        negligence of any nature which may be suffered by me.
      </Typography>

      <Typography gutterBottom>
        I accept that in the event of my breach of any of the above terms, I may
        be suspended or expelled from classes and in such event shall forfeit
        membership fees already paid by me.
      </Typography>

      <Typography gutterBottom>
        I understand that no classes are conducted on public holidays, I
        understand that there is a recess at every Christmas, during which time
        no classes are conducted. Full fees are payable for the month of
        December.
      </Typography>

      <Typography gutterBottom>
        I voluntarily accept the risk of suffering injury in the course of my
        tuition, whether on or off Elite's premises and I agree that neither the
        Principals, Instructors nor anyone involved in an incident may be held
        responsible for any injury whatsoever.
      </Typography>

      <Typography gutterBottom>
        I consent to the irrevocable and unrestricted use of any photos, videos
        or other media taken of me during events, activities and related
        functions for marketing, promotional and social media purposes. This
        includes, but is not limited to publication on our website, social media
        channels, promotional materials and any other collateral, without any
        further compensation or notification.
      </Typography>

      <Typography gutterBottom>
        I confirm that this is application shall become effective and binding on
        me immediately upon acceptance by Elite or his duly authorized agent and
        that signature by him/her on this form shall constitute acceptance of my
        application and dispense with express of formal notice of acceptance.
      </Typography>
    </Stack>
  );
}
