import { PaletteColorOptions } from '@mui/material';
import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';

export const colorPrimary = '#000';
export const colorSecondary = '#000';
export const defaultFontSize = 16;

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1170,
      xl: 1530
    }
  }
});

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colorPrimary
    },
    secondary: {
      main: colorSecondary
    }
  },
  typography: {
    fontFamily: '"Open Sans", Calibri, sans-serif',
    overline: {
      fontWeight: 'bold',
      margin: 0
    }
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", Calibri, sans-serif'
        }
      }
    }
  }
};
